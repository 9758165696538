import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import '../assets/styles/pages/project.scss';
import { useQueryParam, StringParam } from 'use-query-params';
import {
  Layout,
  ProjectInnerCard as ProjectCard,
  SEO,
  UpcomingProjectsSlider
} from '../components';

const Project = ({ pageContext: { data: project_data, meta_data }, ...props }) => {
  const [projects, setProjects] = useState(null);
  const [artistParam, setArtistParam] = useQueryParam('artist', StringParam);
  const [concerts, setConcerts] = useState(null);
  const project_meta_inner = meta_data?.data;

  useEffect(() => {
    fetch(
      `${process.env.API_URL}/api/projects/${props.location.pathname
        .replace(/\//g, '')
        .replace('projects', '')}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (artistParam !== undefined && artistParam !== '') {
          const { projects: newProjects } = filterArtistProjects(res, artistParam);
          setConcerts(newProjects);
        } else {
          setConcerts(res.concerts);
        }
        setProjects(res);
      });
  }, [props.location.pathname]);

  function filterArtistProjects (data, artistParam) {
    const projects = data.concerts.filter((concert) => {
      const { other_artists, artists: def_artists } =
        concert !== undefined && concert !== null && concert;
      let artists = [];

      if (
        other_artists !== null &&
        other_artists !== undefined &&
        other_artists.length &&
        def_artists !== null &&
        def_artists !== undefined &&
        def_artists.length
      ) {
        artists = [...def_artists, ...other_artists];
      } else if (
        def_artists !== null &&
        def_artists !== undefined &&
        def_artists.length
      ) {
        artists = def_artists;
      } else if (
        other_artists !== null &&
        other_artists !== undefined &&
        other_artists.length
      ) {
        artists = other_artists;
      } else {
        artists = def_artists;
      }

      const newConcert =
        artists.find((artist) => {
          console.log(artist);
          return artist.slug === artistParam;
        }) && concert;

      return newConcert;
    });

    return { projects };
  }

  return (
    <>
      <Layout>
        <SEO
          url={`${process.env.BASE_URL}/projects/${project_data?.slug}/`}
          title={
            project_meta_inner?.title ||
            'MusicRoom I Contemporary Classics I Learn about the Project'
          }
          description={
            project_meta_inner?.description ||
            'MusicRoom’s flexible approach to each project draws from experience gained while working with a broad range of organisations and artists.'
          }
          showDefaultTitle={false}
          image={project_data?.image || `${process.env.BASE_URL}/svgIcons/logo.png`}
          squareImg
        />

        <div className='project__hero'>
          <h2 className='project__hero__title'>{project_data?.title}</h2>
          <div
            className='project__hero__img'
            style={{ backgroundImage: 'url(/images/projects-header.png)' }}
          />
        </div>

        <div className='project'>
          {projects && concerts ? (
            <>
              {concerts.map(concert => (
                <ProjectCard
                  key={concert.slug}
                  category={projects.category}
                  data={concert}
                />
              ))}
            </>
          ) : (
            ''
          )}
        </div>

        {projects?.upcoming ? (
          <div className='project__upcomings'>
            <UpcomingProjectsSlider
              data={projects?.upcoming}
              showSectionTitle
              sectionTitle={
                <div className='project__upcomings__text'>
                  <p className='title'>
                    Upcoming <br />
                    <span className='stroked__text'>Projects</span>
                  </p>
                </div>
              }
            />
          </div>
        ) : (
          ''
        )}
      </Layout>
    </>
  );
};

Project.propTypes = {
  pageContext: object,
  location: object,
};

export default Project;
